<template>
	<!-- Information Card 2 -->
	<a-card :bordered="false" class="card-info-2 h-full">
		<div class="col-content h-full" style="background-image: url('images/info-card-2.jpg')">
			<div class="card-content">
				<h5>你会推荐给你的朋友吗？</h5>
				<p>我们是一款Al智能生产力工具，可以快速提高您的工作效率，让您更加轻松地完 成各种任务。同时，我们还是您的贴身工作助手，为您提供更多的便利和帮助。 更重要的是，我们还有丰厚的佣金奖励机制，只要您向好友推荐，并成功注册使用，即可获得相应佣金。所以，不仅可以提升您的工作效率，还可以赚钱，何乐而不为呢？赶快推荐给您的好朋友吧！</p>
			</div>
			<!-- <div class="card-footer">
				<a href="#" size="small">
					<span>Read More</span>
					<svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path fill-rule="evenodd" clip-rule="evenodd" d="M7.29289 14.7071C6.90237 14.3166 6.90237 13.6834 7.29289 13.2929L10.5858 10L7.29289 6.70711C6.90237 6.31658 6.90237 5.68342 7.29289 5.29289C7.68342 4.90237 8.31658 4.90237 8.70711 5.29289L12.7071 9.29289C13.0976 9.68342 13.0976 10.3166 12.7071 10.7071L8.70711 14.7071C8.31658 15.0976 7.68342 15.0976 7.29289 14.7071Z" fill="#111827" />
					</svg>
				</a>
			</div> -->
		</div>
	</a-card>
	<!-- / Information Card 2 -->
</template>

<script>
	export default {
		data() {
			return {}
		}
	}
</script>
