<template>
	<a-card :bordered="false" class="dashboard-bar-line header-solid">
		<template #title>
			<h6>Token消耗量</h6>
			<!-- <p>than last year <span class="text-success">+20%</span></p> -->
		</template>
		<template #extra>
			<!-- <a-badge color="primary" class="badge-dot-primary" text="Traffic" />
			<a-badge color="primary" class="badge-dot-secondary" text="Sales" /> -->
		</template>
		<chart-line :height="310" :data="lineChartData"></chart-line>
	</a-card>
</template>

<script>
	// Bar chart for "Active Users" card.
	import ChartLine from "../Charts/ChartLine"

	export default {
		components: {
			ChartLine
		},
		props: {
			tokenChart: {
				type: Object,
				default: () => {}
			}
		},
		data() {
			return {
				// Data for line chart.
				lineChartData: {
					labels: [],
					datasets: [
						{
							label: "数量",
							tension: 0.4,
							borderWidth: 0,
							pointRadius: 0,
							borderColor: "#1890FF",
							borderWidth: 3,
							data: [],
							maxBarThickness: 6
						}
					]
				}
			}
		},
		watch: {
			tokenChart: {
				deep: true,
				handler(newValue, oldValue) {
					this.lineChartData.labels = newValue.keys
					this.lineChartData.datasets[0].data = newValue.values
				}
			}
		}
	}
</script>
