<template>
	<a-card :bordered="false" class="card-info">
		<a-row type="flex">
			<a-col class="col-content" :span="24" :xl="12">
				<div class="card-content">
					<!-- <h6>Built by developers</h6> -->
					<h5>AI智能创作平台</h5>
					<p>AI智能艺术平台，拥有丰富多彩的创作功能，包括: Al绘画，语音对话等。我们也正在努力开放更多的平台和功能，让您可以尽情发挥创造力，创作出更加惊艳的作品!而且，我们还提供伪原创功能，让您的创作更加独具特色和个性化。所以，不要错过这个机会。</p>
				</div>
				<!-- <div class="card-footer">
					<a href="#" size="small">
						<span>Read More</span>
						<svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path fill-rule="evenodd" clip-rule="evenodd" d="M7.29289 14.7071C6.90237 14.3166 6.90237 13.6834 7.29289 13.2929L10.5858 10L7.29289 6.70711C6.90237 6.31658 6.90237 5.68342 7.29289 5.29289C7.68342 4.90237 8.31658 4.90237 8.70711 5.29289L12.7071 9.29289C13.0976 9.68342 13.0976 10.3166 12.7071 10.7071L8.70711 14.7071C8.31658 15.0976 7.68342 15.0976 7.29289 14.7071Z" fill="#111827" />
						</svg>
					</a>
				</div> -->
			</a-col>
			<a-col class="col-img" :span="24" :xl="12">
				<div class="card-img-bg">
					<img src="images/info-card-1.jpg" alt="" />
				</div>
			</a-col>
		</a-row>
	</a-card>
</template>

<script>
	export default {
		data() {
			return {}
		}
	}
</script>
